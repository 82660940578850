import { add } from 'lodash';
import Vue from 'vue'
import moment from 'moment'

Vue.filter('dateWithoutTime', (value: string) => {
    if(value?.includes('T')) {
        return value?.slice(0,value.indexOf('T'));
    } else {
        return value;
    }
})

Vue.filter('dateWithTime', (value: string) => {
    if (value) {
        const date = moment(value);
    
        return date.format('YYYY-MM-DD | HH:mm');
    }

    return null
});

Vue.filter('dateGetTime', (value: string) => {
    const date = new Date(value);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${hours}:${minutes}`;
})

Vue.filter('encodeString', (value: string) => {
    // eslint-disable-next-line no-useless-escape
    return String(value).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
})

Vue.filter('slugify', (value: string) => {
    return String(value)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
})

function addCopy2dToClipboard() {
    Vue.filter('copy2DToClipboard', (array: any[]) => {
        let csv = '', row, cell;
        for (row = 0; row < array.length; row++) {
            for (cell = 0; cell < array[row].length; cell++) {
            csv += (array[row][cell]+'').replace(/[\n\t]+/g, ' ');
            if (cell+1 < array[row].length) csv += '\t';
            }
            if (row+1 < array.length) csv += '\n';
        }
        copyTextToClipboard(csv)
    })

    // copied from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
    function fallbackCopyTextToClipboard(text: string) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
    function copyTextToClipboard(text: string) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            // console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }
}

addCopy2dToClipboard();

function addErrorHandler() {
    Vue.filter('errorHandler', (error: any) => {
        const defaultMessage = 'Something went wrong. Try again later.'
        let errorMessage = defaultMessage;

        if(error.response?.data?.label) {
            errorMessage = error.response.data.label;
        }

        if(errorMessage == 'error.unexpected') {
            errorMessage = defaultMessage;
        }

        if(errorMessage == defaultMessage) {
            if(error.response?.data?.violations.length > 0) {
                errorMessage = `${capitalizeFirstLetter(error.response.data.violations[0].propertyPath)} - ${error.response.data.violations[0].title}`;
            }
        }

        if (errorMessage === '20c6a0ff-c6a6-4ca3-ab05-551523a98f61') {
            errorMessage = 'Campaign is not active'
        }

        if (errorMessage === 'c22631ff-28a0-4b5f-bbd6-14fcef1d69d9') {
            errorMessage = 'Lead belongs to another advisor'
        }
    
        if(errorMessage == '9e4235af-9aa7-4489-b507-71851ff1448f' && error.response?.data?.violations.length > 0) {
            errorMessage = `${mapPropertyPath(error.response.data.violations[0].propertyPath)} - ${error.response.data.violations[0].message}`;
        }
    
        return errorMessage;
    })

    function capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function mapPropertyPath(value: string) {
        if(value == 'counterPartyTin') {
            return 'Counterparty TIN';
        } else if(value == 'counterPartyName') {
            return 'Counterparty Name';
        } else if(value == 'counterPartyStreet') {
            return 'Street'
        } else if(value == 'counterPartyStreet') {
            return 'Street'
        } else if(value == 'counterPartyStreetNumber') {
            return 'Street number'
        } else if(value == 'counterPartyApartmentNumber') {
            return 'Local'
        } else if(value == 'counterPartyCity') {
            return 'City'
        } else if(value == 'counterPartyZipCode') {
            return 'ZIP code'
        } else if(value == 'counterPartyCountry') {
            return 'Country'
        } else if(value == 'type') {
            return 'Document type'
        } else if(value == 'documentNumber') {
            return 'Document number'
        } else if(value == 'correctedDocumentNumber') {
            return 'Corrected document number'
        } else if(value == 'issueDate') {
            return 'Issue date'
        } else if(value == 'paymentDate') {
            return 'Payment date'
        } else if(value == 'saleDate') {
            return 'Sale date'
        } else if(value == 'additionDate') {
            return 'Addition date'
        } else if(value == 'paymentMethod') {
            return 'Payment method'
        } else if(value == 'paymentStatus') {
            return 'Payment status'
        } else if(value == 'currency') {
            return 'Currency'
        } else if(value == 'bankAccount') {
            return 'Bank account'
        } else if(value == 'bankAccountCountry') {
            return 'Bank account country'
        } else if(value == 'glAccount') {
            return 'GL account'
        } else if(value == 'costCenter') {
            return 'Cost center'
        }

        return value;
    }
}

addErrorHandler();

Vue.filter('getIcon', (iconName: string) => {
   switch(iconName) {
       //Menu icons >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
       case("Welcome"):
           return require('@/assets/icons/home.svg');
       case("My profile"):
           return require('@/assets/icons/person.svg');
       case("Fund"):
           return require('@/assets/icons/fund.svg');
       case("Insights"):
           return require('@/assets/icons/insights.svg');
       case("Company"):
           return require('@/assets/icons/company.svg');
       case("Investor"):
           return require('@/assets/icons/investors.svg');
       case("AIFM"):
           return require('@/assets/icons/AIFM.svg');
       case("Marketplace"):
           return require('@/assets/icons/marketplace.svg');
       case("My wallet"):
           return require('@/assets/icons/wallet.svg');
           //Menu icons <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
       case("DOWNLOAD"):
           return require('@/assets/icons/download.svg');
       case("DOCS"):
           return require('@/assets/icons/docs-black.svg');
       case("APPROVE"):
           return require('@/assets/icons/checked-alt.svg');
       case("ACCEPT"):
           return require('@/assets/icons/accept.svg');
       case("REJECT"):
           return require('@/assets/icons/reject.svg');
       case("REJECT GRAY"):
           return require('@/assets/icons/reject-gray.svg');
       case("EDIT"):
           return require('@/assets/icons/edit.svg');
       case("DELETE"):
           return require('@/assets/icons/bin.svg');
       case("ARROW"):
           return require('@/assets/icons/arrow.svg');
       case("ARROW UP"):
           return require('@/assets/icons/arrow-up.svg');
       case("ARROW RED"):
           return require('@/assets/icons/arrow-red.svg');
       case("LOCK RED"):
           return require('@/assets/icons/lock-red.svg');
       case("WALLET"):
           return require('@/assets/icons/wallet.svg');
       case("ROTATE"):
           return require('@/assets/icons/rotate.svg');
       case("DOTS"):
           return require('@/assets/icons/dots.svg');
       case("X"):
           return require('@/assets/icons/x-red.svg');
        case("PENDING"):
            return require('@/assets/icons/pending.svg'); 
       case("PADLOCK"):
           return require('@/assets/icons/padlock-grey.svg');
        case('DB ARROW LEFT'):
            return require('@/assets/icons/double-arrow-left.svg');
        case('DB ARROW RIGHT'):
            return require('@/assets/icons/double-arrow-right.svg');
        case("APPROVE 2"):
            return require('@/assets/icons/approve.svg');
        case("REJECT 2"):
            return require('@/assets/icons/reject-2.svg');
        case("UNDO"):
            return require('@/assets/icons/undo.svg');
        case("BOOK"):
            return require('@/assets/icons/sign-in3.svg');
        case("SETTINGS"):
            return require('@/assets/icons/cogwheel.svg');
        case("UPLOAD"):
            return require('@/assets/icons/upload.svg');
        case("LETTER"):
            return require('@/assets/icons/letter.svg');
        case("LETTER BLACK"):
            return require('@/assets/icons/letter-black.svg');
        case("CALENDAR"):
            return require('@/assets/icons/calendar.svg');
        case("WWW"):
            return require('@/assets/icons/www.svg');
        case("AUTENTI"):
            return require('@/assets/icons/autenti-icon.svg');
        case("REFRESH"):
            return require('@/assets/icons/refresh.svg');
   }
   return ''
});

Vue.filter('removeKey', (object: any, keyName: string) => {
    const {[keyName]: deletedKey, ...otherKeys} = object;
    return otherKeys;
});

Vue.filter('capitalizeFirstLetter', (value: string) => {
    if(!value) return '';
    return value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
});

Vue.filter('snakeCaseToTitleCase', (s: string) => {
        const formattedString = s?.toLowerCase().replace(/(^\w)/g, g => g[0].toUpperCase()).replace(/([-_]\w)/g, g => " " + g[1].toUpperCase()).trim();
        return formattedString;
})

Vue.filter('uppercase', (value: string) => {
    return value.toUpperCase();
});

Vue.filter('toPhrase', (value: string) => {
    let formattedValue: string = value

    formattedValue = `${formattedValue[0].toUpperCase()}${formattedValue.slice(1, formattedValue.length)}`

    return formattedValue
})

Vue.filter('toHttps', (value: string) => {
    let formattedValue: string = value
    formattedValue = `https://${value?.replaceAll('https://', '')?.replaceAll('http://', '')}`

    return formattedValue
})

Vue.filter('bankAccountNumber', (value: string) => {
    if (!value) return ''
    let returnValue: string = '';
    const strippedValue: string = value?.replace(/[^\dA-Z]/g, '');
    if(value?.charAt(0)?.match(/^[0-9]*$/)){
        returnValue += String(strippedValue.slice(0,2));
        returnValue += ' ';
        returnValue += String(strippedValue).slice(2,strippedValue.length).replace(/(.{4})/g, '$1 ').trim();
    } else {
        returnValue = String(strippedValue).replace(/(.{4})/g, '$1 ').trim();
    }
    return returnValue;
});

Vue.filter('trimString', (value: string | null, chars: number = 30): string => {
    if(!value) return ''; 
    return (value.length <= chars) ? value : `${String(value).substring(0, chars)}...`;
});

Vue.filter('trimWords', (value: string | null, words: number = 30): string => {
    if(!value) return '';
    return value.split(' ').length > words ? `${value.split(' ').slice(0, words).join(' ')}...` : value.split(' ').slice(0, words).join(' ');
})

Vue.filter('formatPeriod', (value: string, period: string) => {
    if (value) {
        switch (period) {
            case 'YEAR':
                return moment(String(value)).format('YYYY') + 'Y';
            case 'QUARTER':
                return moment(String(value)).format('YYYY.Q') + 'Q';
            case 'MONTH':
                return moment(String(value)).format('MMM YYYY');
            default:
                return value;
        }
    }
});

Vue.filter('leadingZeroDigitFormat', function(value: any, length: number = 3, blank: boolean = false) {
    if (blank && !value) return ''
    if(value === undefined || value === null) value = "0";
    let paddingString = "";


    for (let i = 0; i < length; i++) {
        paddingString += "0";
    }

    return (paddingString + value).slice(-paddingString.length);
});

function addVueDataFormat() {
    Vue.filter('numberFormat', numberFormat);

    function numberFormat(value: any, roundTo = 2, isCurrency = false) {
        if(value === undefined) value = 0;
        if(isCurrency) {
            if(!value) return null;
            if(value !== undefined && value !== null) {
                return getRoundedValue(value, roundTo);
            }
            return 0;
        } else {
            if (value !== undefined && value !== null) {
                return getRoundedValue(value, roundTo);
            }
            if(value === null) return null;
            return 0;
        }
    }

    Vue.filter('abbrNumber', (num: any) => {
        if (String(num).length < 7 && String(num).length > 3 && num % 1000 === 0) {
            return Math.floor(num/1000) + 'k';
        } else if(String(num).length >= 7 && num % 1000000 === 0) {
            return Math.floor(num/1000000) + 'mln';
        } else {
            return numberFormat(String(num), 2);
        }
    })

    function getRoundedValue(value: any, roundTo: number) {
        value = value + '';
        // Replace the comma with dot to make string which will be parsed to number.
        value = value.replace(/,/g, '.');

        // TODO: I don't know what exactly here be, but it seems to be two types of spaces. Ensure to replace all of them.
        value = value.replace(/ /g, '');
        // eslint-disable-next-line no-irregular-whitespace
        value = value.replace(/ /g, '');
        // Parse string to number.
        value = Number(value);

        // If the value is not a number, replace it to 0.
        if (!value) {
            value = 0;
        }

        // Get where is a dot.
        const dotIndex = value.toString().indexOf('.');

        // If the dot doesn't exist it means that number is rounded eg 3, 11, 923.
        if (dotIndex === -1 || roundTo === 0) {
            // So, round the number to 2 decimals eg 3.00, 11.00, 923.000
            value = value.toFixed(roundTo);
        } else {
            // If the dot exists, it means the number may be like 3.02323, 11.2
            // So, round its to the 'this.roundTo' value.
            // Eg 3.02, 11.20. (for this.roundTo = 2)
            value = value.toString().substring(0, dotIndex + roundTo + 1);

            // Get how many decimals number the value has.
            // Eg 12.1 => 3, 12.233 = 3
            const decimalsLength = value.split('.')[1].length;

            // If the number is smaller than the roundTo value, it means that the value has to be filled by zeros.
            // Eg.
            // value = 12.3;
            // this.roundTo = 3;
            // this.decimalsLength = 1;
            // So, the value should has 3 digits after comma, currently has only 1.
            // In this case, convert 12.3 to 12.300.
            if (decimalsLength < roundTo) {

                const fillAmount = roundTo - decimalsLength;

                value += '0'.repeat(fillAmount);
            }
        }

        // At the end, convert the dot to the comma.
        // Eg. 12.26 => 12,26
        value = value.replace(/\./g, ',');

        // And format the value to get spaces between thousands.
        const splitted = value.split('.');
        splitted[0] = splitted[0].replace(/\B(?=(\d{3})+(?!\d),)/g, ' ');
        value = splitted.join('.');

        return value;
    }

    Vue.filter('thousandSeparator', function (value: any, separator = ' ') {
        if (value !== null && value !== '' && !isNaN(value)) {
            let result = parseFloat(value).toFixed(0).toString();
            if(separator) result = result.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
            return result
        } else {
            return null
        }
    });

    Vue.filter('integer', function (value: any) {
        if (!value?.toString()) return ''
        
        
        const result = parseFloat(value).toFixed(0).toString();

        if (isNaN(value)) {
            return null
        } 
        
        return result.toString()
    });

    Vue.directive('roundTo', {
        bind: function (el: HTMLElement, binding) {

            el.onblur = (event) => {

                // Get current input value.
                let value = (el as HTMLInputElement).value;

                // Return if the input is empty.
                if (!value) {
                    return;
                }

                value = getRoundedValue(value, binding.value);

                // Overwrite the input value.
                (el as HTMLInputElement).value = value;
            }
        }
    })
}

addVueDataFormat();
