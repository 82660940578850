import {DictionaryState} from "@/modules/shared/dictionary-store/types";
import {AuthState} from "@/modules/auth/store/types";
import {InvestorsState} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {GenproxState} from "@/modules/genprox/store/types";

export interface RootState {
    genprox: GenproxState,
    auth: AuthState,
    investors: InvestorsState,
    dictionary: DictionaryState
}

export interface SelectOption<T = any> {
    label: string,
    value: T,
    [key: string]: string | T;
}

export interface CheckboxOption {
  label: string,
  value: boolean,
}

export const FUNDEQUATE_LP_URL = 'https://fundequate.com/pl/';
