
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import {ProfileState, ProfileDataState, BusinessLink, TableData} from './types';
import { BACKEND_API_URL } from "@/shared/consts";
import { UploadFileDTO } from "@/modules/genprox/models/Shared";
import {Product} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {KPIs} from "@/modules/genprox/modules/profile/models/KPIs.interface";
import {_axios as axios} from "@/plugins/axios";
import {Transaction} from "@/modules/genprox/modules/profile/models/transaction.interface";
import {TableQuery} from "@/modules/shared/utils/TableQuery";

export const state: ProfileState = {
    profile: {
        mailingAddressSameResidential: false,
        generalData: undefined,
        details: undefined,
        residentialAddress: undefined,
        mailingAddress: undefined,
    },
    wallet: {
        products: [],
        KPIs: undefined,
        transactionsHistory: [],
    },
    businessLinks: [],
    initialBusinessLinks: [],
    dictionaries: undefined,
    profileByGuest: {
        products: undefined,
    },
    productsTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    },
    transactionsTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    }
}

export const getters: GetterTree<any, RootState> = {
    getProfileData(state) {
        return JSON.parse(JSON.stringify(state.profile));
    },
    getBusinessLinks(state) {
        return JSON.parse(JSON.stringify(state.businessLinks))?.sort((a: any, b: any) => {
            if (a.legalEntityName?.toLowerCase() < b.legalEntityName?.toLowerCase()) {
                return -1;
            }
            if (a.legalEntityName?.toLowerCase() > b.legalEntityName?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    },
    getInitialBusinessLinks(state) {
        return JSON.parse(JSON.stringify(state.initialBusinessLinks));
    },
    getDictionaries(state) {
        return state.dictionaries;
    }
}

export const mutations: MutationTree<ProfileState> = {
    setProfileData(state, payload: any): void {
        state.profile.mailingAddressSameResidential = payload.mailingAddressSameResidential;
        state.profile.generalData = payload.generalData;
        state.profile.details = payload.details;
        state.profile.residentialAddress = payload.residentialAddress;

        if(payload.mailingAddressSameResidential) {
            state.profile.mailingAddress = payload.residentialAddress;
        } else {
            state.profile.mailingAddress = payload.mailingAddress;
        }
    },
    setBusinessLinks(state, payload: any): void {
        state.businessLinks = payload;
    },
    setInitialBusinessLinks(state, payload: any): void {
        state.initialBusinessLinks = payload;
    },
    setDictionaries(state, payload: any): void {
        state.dictionaries = payload;
    },
    setWalletProducts(state, payload: Array<Product>){
        state.wallet.products = payload;
    },
    setKeyKPIs(state, payload: KPIs) {
        state.wallet.KPIs = payload;
    },
    setTransactionsHistory(state, payload: Array<Transaction>){
        state.wallet.transactionsHistory = payload;
    },
    setProductsQuery(state, payload: TableQuery): void {
        state.productsTable.query = payload;
    },
    setProductsTableItems(state, payload: {items: Array<Product>, totalCount: number}): void {
        state.productsTable = { ...state.productsTable, items: payload.items, totalCount: payload.totalCount};
    },
    setTransactionsHistoryQuery(state, payload: TableQuery): void {
        state.transactionsTable.query = payload;
    },
    setTransactionsTableItems(state, payload: TableData<Transaction>): void {
        state.transactionsTable = { ...state.transactionsTable, items: payload.items, totalCount: payload.totalCount};
    },
}

export const actions: ActionTree<ProfileState, RootState> = {
    async getProfileData({commit}): Promise<void> {
        const {data} = await axios.get(`${BACKEND_API_URL}/profile`);
        commit('setProfileData', data);
    },
    async updateProfileData({commit, dispatch}, payload: ProfileDataState): Promise<void> {
        let sendAddresses = false;

        if(payload.residentialAddress && payload.mailingAddress) {
            sendAddresses = Object.values(payload.residentialAddress).some(el => el);

            if(!sendAddresses) {
                sendAddresses = Object.values(payload.mailingAddress).some(el => el);
            }
        }

        if(!sendAddresses) {
            payload.mailingAddressSameResidential = true;
            payload.residentialAddress = null;
            payload.mailingAddress = null;
        }

        await axios.put(`${BACKEND_API_URL}/profile`, payload);
        await dispatch('getProfileData');
        await dispatch('getBusinessLinks', true);
    },
    async getBusinessLinks({commit}, setInitial: boolean | undefined): Promise<void> {
        const {data} = await axios.get(`${BACKEND_API_URL}/profile/business-link`);
        commit('setBusinessLinks', data?.sort((a: any, b: any) => {
            if (a.legalEntityName?.toLowerCase() < b.legalEntityName?.toLowerCase()) {
                return -1;
            }
            if (a.legalEntityName?.toLowerCase() > b.legalEntityName?.toLowerCase()) {
                return 1;
            }
            return 0;
        }));
        if(setInitial) {
            commit('setInitialBusinessLinks', data?.sort((a: any, b: any) => {
            if (a.legalEntityName?.toLowerCase() < b.legalEntityName?.toLowerCase()) {
                return -1;
            }
            if (a.legalEntityName?.toLowerCase() > b.legalEntityName?.toLowerCase()) {
                return 1;
            }
            return 0;
        }));
        }
    },
    async getUserBusinessLinks({commit}, id: string): Promise<BusinessLink> {
        const {data} = await axios.get(`${BACKEND_API_URL}/profile/${id}/business-link`)
        return data;
    },
    async updateBusinessLinks({commit, dispatch}, payload: any): Promise<void> {
        const {data} = await axios.put(`${BACKEND_API_URL}/profile/business-link`, payload);
    },
    async uploadPhoto({commit, dispatch}, payload: UploadFileDTO): Promise<void> {
        const formData = new FormData();
        formData.append('file', payload.file);
        const {data} = await axios.post(`${BACKEND_API_URL}/profile/photo`, formData);
    },
    async deletePhoto({commit, dispatch}): Promise<void> {
        const {data} = await axios.delete(`${BACKEND_API_URL}/profile/photo`);
    },
    async getDictionaries({commit}) {
        const {data} = await axios.get(`${BACKEND_API_URL}/profile/dictionaries`);

        data.title = Object.values(data.title).map((el: any) => {
            return {label: el, value: el};
        });

        data.investmentPotential = Object.values(data.investmentPotential).map((el: any) => {
            return {label: el, value: el};
        });

        data.investmentKnowledge = Object.values(data.investmentKnowledge).map((el: any) => {
            return {label: el, value: el};
        });

        data.industry = Object.entries(data.industry).map((el: any) => {
            return {label: el[1], value: el[0]};
        });

        data.focusArea = Object.values(data.focusArea).map((el: any) => {
            return {label: el, value: el};
        });
        commit('setDictionaries', data);
    },
    async getWalletProductsForLoggedUser({state,commit}){
        const tableQuery = state.productsTable.query;
        const response = await axios.get(`${BACKEND_API_URL}/wallet/products${tableQuery.getStringQuery()}`,
          { headers: { 'x-total-count': true} });
        const data: TableData<Product> = { ...state.productsTable,
            totalCount: response.headers['x-total-count'], items: response.data };
        commit('setWalletProducts', data);
        commit('setProductsTableItems', data);
        return data;
    },
    async getActiveUserTransactionsHistory({state, commit}) {
        const tableQuery = state.transactionsTable.query;
        const response = await axios.get<Array<Transaction>>(
          `${BACKEND_API_URL}/wallet/transaction-history${tableQuery.getStringQuery()}`,
          { headers: { 'x-total-count': true} });
        const data: TableData<Transaction> = { ...state.transactionsTable,
            totalCount: response.headers['x-total-count'], items: response.data };
        commit('setTransactionsHistory', data);
        commit('setTransactionsTableItems', data);
        return data;
    },
    async getKeyKPIsForLoggedUser({commit}){
        const { data } = await axios.get(`${BACKEND_API_URL}/wallet/key-kpis`);
        commit('setKeyKPIs', data);
    },
    async getKeyKPIsForUser({commit}, id: string){
        try {
            return (await axios.get(`${BACKEND_API_URL}/wallet/key-kpis`)).data;
        } catch (e){
            console.log(e);
        }
    },
    async userProfile({state, commit}, id: string): Promise<ProfileDataState> {
        return (await axios.get<ProfileDataState>(`${BACKEND_API_URL}/profile/${id}`))?.data;
    }
}

export const marketplace: Module<any, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export const profile: Module<ProfileState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
