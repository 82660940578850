var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-side-bar"
  }, _vm._l(_vm.menus, function (menu) {
    return _c('sygni-menu', {
      key: menu.label,
      staticClass: "app-side-bar__menu",
      attrs: {
        "menu": menu,
        "activeMenu": _vm.activeMenu
      },
      on: {
        "activeMenuChange": _vm.onActiveMenuChange
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }