var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-select sygni-context-select', _vm.disabled ? 'disabled' : '', _vm.preserveSearch ? 'preserved' : '', _vm.emptyClass]
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('multiselect', _vm._g(_vm._b({
    ref: "multiSelect",
    attrs: {
      "options": _vm.contextOptions,
      "value": _vm.optionValue,
      "label": _vm.optionsLabel,
      "track-by": "label",
      "maxHeight": 311,
      "showLabels": false,
      "openDirection": 'below',
      "preselectFirst": _vm.preselectFirst,
      "preserveSearch": _vm.preserveSearch,
      "clearOnSelect": _vm.clearOnSelect,
      "internalSearch": _vm.internalSearch,
      "optionsLimit": _vm.limit,
      "trackBy": _vm.trackBy,
      "show-no-results": false
    },
    on: {
      "input": function input($event) {
        return _vm.handleInputEvent($event);
      },
      "search-change": _vm.searchChangeAction,
      "blur": function blur($event) {
        return _vm.validation.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(_ref) {
        var _option$label, _option$data, _option$label2;

        var option = _ref.option;
        return [_c('user-presentation', {
          attrs: {
            "name": (option === null || option === void 0 ? void 0 : (_option$label = option.label) === null || _option$label === void 0 ? void 0 : _option$label.toLowerCase()) === 'personal account' ? _vm.userName : option === null || option === void 0 ? void 0 : option.label,
            "image": option === null || option === void 0 ? void 0 : (_option$data = option.data) === null || _option$data === void 0 ? void 0 : _option$data.logo,
            "description": (option === null || option === void 0 ? void 0 : (_option$label2 = option.label) === null || _option$label2 === void 0 ? void 0 : _option$label2.toLowerCase()) === 'personal account' ? _vm.userDescription : ''
          }
        })];
      }
    }, _vm.addOptionBtn ? {
      key: "beforeList",
      fn: function fn() {
        return [_c('button', {
          staticClass: "sygni-select__add-option",
          on: {
            "click": _vm.addOption
          }
        }, [_vm._v("Add new counterparty +")])];
      },
      proxy: true
    } : null, {
      key: "option",
      fn: function fn(props) {
        var _props$option, _props$option2, _props$option2$data;

        return [_c('user-presentation', {
          attrs: {
            "name": (_props$option = props.option) === null || _props$option === void 0 ? void 0 : _props$option.label,
            "image": (_props$option2 = props.option) === null || _props$option2 === void 0 ? void 0 : (_props$option2$data = _props$option2.data) === null || _props$option2$data === void 0 ? void 0 : _props$option2$data.logo
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn() {
              var _props$option3, _props$option3$label, _props$option4, _props$option4$label, _vm$userName, _props$option5, _props$option6, _props$option7, _props$option7$data, _vm$user, _vm$user2;

              return [(props === null || props === void 0 ? void 0 : (_props$option3 = props.option) === null || _props$option3 === void 0 ? void 0 : (_props$option3$label = _props$option3.label) === null || _props$option3$label === void 0 ? void 0 : _props$option3$label.toLowerCase()) !== 'personal account' && (props === null || props === void 0 ? void 0 : (_props$option4 = props.option) === null || _props$option4 === void 0 ? void 0 : (_props$option4$label = _props$option4.label) === null || _props$option4$label === void 0 ? void 0 : _props$option4$label.toLowerCase()) !== ((_vm$userName = _vm.userName) === null || _vm$userName === void 0 ? void 0 : _vm$userName.toLowerCase()) ? [_c('span', {
                key: _vm.value,
                staticClass: "text-black",
                domProps: {
                  "innerHTML": _vm._s(_vm.highlightedText((_props$option5 = props.option) === null || _props$option5 === void 0 ? void 0 : _props$option5.label, (_props$option6 = props.option) === null || _props$option6 === void 0 ? void 0 : _props$option6.data))
                }
              }), (props === null || props === void 0 ? void 0 : (_props$option7 = props.option) === null || _props$option7 === void 0 ? void 0 : (_props$option7$data = _props$option7.data) === null || _props$option7$data === void 0 ? void 0 : _props$option7$data.context) === 'advisor-panel' ? _c('div', {
                staticClass: "tag"
              }, [_vm._v("Broker panel")]) : _vm._e()] : [_c('span', {
                key: _vm.value,
                staticClass: "text-black",
                domProps: {
                  "innerHTML": _vm._s(_vm.highlightedText("".concat((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.firstName, " ").concat((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.surname), props.option.data))
                }
              }), _c('div', {
                staticClass: "subtext"
              }, [_vm._v(_vm._s(_vm.userDescription))])]];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "afterList",
      fn: function fn() {
        return [_vm.hasAfterlist ? _c('div', {
          staticClass: "no-option-found"
        }, [_c('sygni-rect-button', {
          staticClass: "filled red",
          on: {
            "click": _vm.addNewFundManagerHandle
          }
        }, [_vm._v("Add new Fund Manager")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, true)
  }, 'multiselect', Object.assign({}, _vm.removeKey(Object.assign({}, _vm.$props), 'value'), _vm.removeKey(Object.assign({}, _vm.$attrs), 'value')), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input'))), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }