import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {FUNDEQUATE_LP_URL, RootState} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import { BACKEND_API_URL, BACKEND_BASE_URL} from "@/shared/consts";
import {AccessModules, Flag, FlaggedObjectPayload, FlagObject, FundSelectObject, GenproxState, WelcomeTextData} from "@/modules/genprox/store/types";
import { Address } from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import Utils from '@/modules/shared/utils/utils';
import {AcceptPackageDTO, Package} from "@/modules/genprox/models/Package";
import {Contexts, MTMember, UserContext} from "@/modules/genprox/models/User";
import {UploadFileDTO} from "@/modules/genprox/models/Shared";
import {ActiveUserData} from "@/modules/genprox/models/ActiveUserData";
import {GetActiveUserDataResponse} from "@/modules/genprox/models/GetActiveUserDataResponse";
import {Wallet} from "@/shared/interfaces/Wallet";
import router from "@/router";

export const state: GenproxState = {
    userContexts: [],
    selectOptions: {
        countries: [],
        brokerPermissions: [],
    },
    activeContextMarketplaceWallets: [],
    menu: [],
    legalRouting: [],
    managementTeam: [],
    busyTable: false,
    packages: [],
    welcomeText: null,
    activeUserData: null,
    accessModules: {
        accounting: false,
        regulatoryReporting: false,
        managementReporting: false,
        portfolio: false,
        capital: false,
        marketplace: false,
        whistleblower: false,
    },
    flags: [],
    networks: [],
    employees: [],
    funds: [],
    issuers: [],
    documentDetails: [
        // product fields
        'Product Code',
        'Product Name',
        'Product Type',
        'Agreement Date',
        'Investment Value',
        'Investment Value in English',
        'Investment Value in Polish',
        'Fixed Repayment Day',
        'End Date',
        'Enforcement Clause Date',
        'Duration',
        'Duration Count',
        'Interest Type',
        'Interest P.A.',
        'Interest P.A. in English',
        'Interest P.A. in Polish',
        'Interest Payment',
        'Commission P.A.',
        'Commission Type',
        'Margin P.A.',
        'Commission Payment',
        'Margin Payment',
        'Interest Calculation Method',
        'Interest Paid Within',
        'Initial Payment',
        'Initial Payment Bank Name',
        'Principal Repayment',
        'Principal Repayment Bank Name',
        'Interest Repayment',
        'Interest Repayment Bank Name',
        'Debtor',
        'Debtor Bank Name',
        'Bill Number',
        'Currency Name & Symbol',
        'Currency Name',
        'Annexed Product Codes',
        'Annexed Agreement Dates',
        'Annexed End Dates',
        'Annexed Investment Values',
        // series of shares
        'Series Counter From',
        'Series Counter From in English',
        'Series Counter From in Polish',
        'Series Counter To',
        'Series Counter To in English',
        'Series Counter To in Polish',
        'Series Instrument Count',
        'Series Instrument Count in English',
        'Series Instrument Count in Polish',
        'Series Serial Number',
        'Series Nominal Value',
        'Series Nominal Value in English',
        'Series Nominal Value in Polish',
        'Series Issue Value',
        'Series Issue Value in English',
        'Series Issue Value in Polish',
        'Series Investment Value',
        'Series Investment Value in English',
        'Series Investment Value in Polish',
        'Series Capital Value',
        'Series Capital Value in English',
        'Series Capital Value in Polish',
        // investor fields
        'Investor Name',
        'Investor First Name',
        'Investor Surname',
        'Investor Street (residential address)',
        'Investor House Number (residential address)',
        'Investor Flat Number (residential address)',
        'Investor City (residential address)',
        'Investor Zip Code (residential address)',
        'Investor Country (residential address)',
        'Investor Street (mailing address)',
        'Investor House Number (mailing address)',
        'Investor Flat Number (mailing address)',
        'Investor City (mailing address)',
        'Investor Zip Code (mailing address)',
        'Investor Country (mailing address)',
        // issuer fields
        'Issuer Name',
        'Issuer Street',
        'Issuer House Number',
        'Issuer Flat Number',
        'Issuer City',
        'Issuer Zip Code',
        'Issuer Country',
        'Issuer Register Number',
        'Issuer Tax Number',
        'Issuer Statistical Number',
        // investor details fields
        'Investor Identification No.',
        'Investor Document Type',
        'Investor Document Type in Polish',
        'Investor Document Number',
        'Investor Document Country',
        'Investor Document Expiry Date',
        'Investor Country of Tax Residence',
        'Investor Middle Name',
        'Investor Birthday',
        'Investor Birth Place',
        'Investor Fathers Name',
        'Investor Mothers Name',
        'Investor Phone No.',
        'Investor Email',
        'Investor Nationality',
        // advisor fields
        'Advisor Email',
        'Advisor Name',
        'Advisor Identification No.',
        'Advisor Document Type',
        'Advisor Document Number',
        'Advisor Document Country',
        'Advisor Document Expiry Date',
        'Advisor Start Power of Attorney',
        'Advisor End Power of Attorney',
        // subscription fields
        'Subscription Investment Value',
        'Subscription Investment Value in English',
        'Subscription Investment Value in Polish',
        'Subscription Agreement Date',
        'Subscription Code',
        'Share Capital',
        'Share Capital in English',
        'Share Capital in Polish',
        'Capital Value',
        'Capital Value in English',
        'Capital Value in Polish',
        'Increased Share Capital',
        'Increased Share Capital in English',
        'Increased Share Capital in Polish',
        'Subscription Summary in English',
        'Subscription Summary in Polish',
        // campaign fields
        'Campaign Name',
        'Campaign Investment Value',
        'Campaign Investment Value in English',
        'Campaign Investment Value in Polish',
        // legal entity fields
        'LE Investor Name',
        'LE Investor Email',
        'LE Investor Tax Number',
        'LE Investor Register Number',
        'LE Investor Statistical Number',
        'LE Investor Street (registration address)',
        'LE Investor House Number (registration address)',
        'LE Investor Flat Number (registration address)',
        'LE Investor City (registration address)',
        'LE Investor Zip Code (registration address)',
        'LE Investor Country (registration address)',
        // other fields
        'Current Date',
    ]
}


export const getters: GetterTree<GenproxState, RootState> = {
    documentDetails(state) {
        return state.documentDetails;
    },
    userContexts(state): Array<UserContext> | null {
        return  [...state.userContexts];
    },
    activeUserContextId(state): string | null {
        return state.activeUserData.context?.id;
    },
    activeContextWallet(state): Array<Wallet> {
        return state.activeContextMarketplaceWallets;
    },
    activeUserData(state): ActiveUserData {
        return state.activeUserData;
    },
    accessModules(state): AccessModules {
        return state.accessModules;
    },
    flags(state): Array<FlagObject> {
        return state.flags;
    },
    networks(state) {
        return state.networks
    },
    employees(state): any {
        return state.employees;
    },
    funds(state): FundSelectObject[] {
        return state.funds;
    },
    issuers (state) {
        return state.issuers
    }
}

export const mutations: MutationTree<GenproxState> = {
    setUserContexts(state, payload: Array<UserContext>): void {
        state.userContexts = payload;
    },
    setActiveUserContext(state, payload: UserContext): void {
        state.activeUserData.context = payload;
    },
    setActiveUserData(state, payload: ActiveUserData): void {
        state.activeUserData = {...state.activeUserData, ...payload};
    },
    setActiveContextMarketplaceWallet(state, payload: Array<Wallet>): void {
        state.activeContextMarketplaceWallets = payload;
    },
    setMenus(state, payload: any): void {
        state.menu = payload;
        state.legalRouting = Utils.getActiveModulesFromMenu(payload);
    },
    setManagementTeam(state, payload: MTMember[]): void {
        state.managementTeam = payload;
    },
    setBusyTable(state,payload): void {
        state.busyTable = payload;
    },
    setPackages(state,payload): void {
        state.packages = payload;
    },
    setAccessModules(state,payload): void {
        state.accessModules = payload;
    },
    setCMSWelcome(state, payload: WelcomeTextData): void {
        state.welcomeText = payload;
    },
    setFlags(state, payload: Array<FlagObject>): void {
        state.flags = payload;
    },
    setNetworks(state, payload: any[]) {
        state.networks = payload;
    },
    setTwoFactorAuth(state, payload: boolean) {
        state.activeUserData.user.twoFactorAuthEnabled = payload;
    },
    setEmployees(state, payload: any) {
        state.employees = payload;
    },
    setFunds(state, payload: FundSelectObject[]): void {
        state.funds = payload;
    },
    setIssuers(state, payload: any[]) {
        state.issuers = payload
    }
}

export const actions: ActionTree<GenproxState, RootState> = {
    async getUserContexts({state, commit}) {
        const { data } = await axios.get<UserContext[]>(`${BACKEND_API_URL}/contexts`);
        commit('setUserContexts', data);
        return data;
    },
    async getActiveUserData({state, commit, dispatch}) {
        const { data } = await axios.get<GetActiveUserDataResponse>(`${BACKEND_API_URL}/auth/data`);
        const parsedData:ActiveUserData = {...data, context: {...data.context, context: data.context.type, logo: ''}};
        commit('setActiveUserData', parsedData);
        return parsedData;
    },
    async setUserContext({state, commit, dispatch}, payload: UserContext) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/auth/change-context`, {
            contextId: payload.id
        });

        commit('auth/setApiKey', data.accessToken, { root: true });
    },
    async getUserPackages({state, commit}){
        const { data } = await axios.get<Package[]>(`${BACKEND_API_URL}/package`);
        commit('setPackages', data);
        return data;
    },
    async getAccessModules({state, commit}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/privileges/access-modules`);
        commit('setAccessModules', data);
        return data;
    },
    async getLegalEntityAccessModules({state, commit}, viewedContext: string) {
        const headers: any = {}

        if (viewedContext) {
            headers['Viewed-Context'] = viewedContext
        }

        const { data } = await axios.get(`${BACKEND_API_URL}/privileges/access-modules`, { headers });

        return data;
    },
    async activePackage({state, commit}, acceptPackageDTO: AcceptPackageDTO){
        try {
            const {data} = await axios.post<any>(`${BACKEND_API_URL}/package/order`, acceptPackageDTO);
            const newPackages = JSON.parse(JSON.stringify(state.packages)).map( (pack: Package) => {
                if(pack.id === acceptPackageDTO.packageId){
                    pack.active = true;
                }
                return pack;
            })
            commit('setPackages', newPackages);
            return data;
        } catch (e){
            return e;
        }
    },
    async getContextMarketplaceWalletNumber({state, commit}): Promise<void> {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/bank-accounts/marketplace`);
        return data;
    },
    async getContextData({commit}): Promise<void> {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/data`);
        return data;
    },
    async getIssuers({state, commit}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/issuer`)

        commit('setIssuers', data)

        return data
    },
    async getLeContextData({commit}, id: string): Promise<void> {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/${id}/data`);
        return data;
    },
    async getFundData(): Promise<boolean | object> {
        try {
            const {data} = await axios.get(`${BACKEND_API_URL}/crm/fund-data`)
            return data;
        } catch (e) {
            return false;
        }
    },
    async getFunds({commit}): Promise<any> {
        try {
            const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/funds`);

            commit('setFunds', data);

            return data;
        } catch (e) {
            return e;
        }
    },
    async askToJoinFund({commit}, id: string): Promise<any> {
        try {
            const { data } = await axios.post(`${BACKEND_API_URL}/employee/ask-to-join/${id}`);

            return data;
        } catch (e) {
            return e;
        }
    },
    async getCompanyData({commit}, viewedContext): Promise<boolean | object> {
        try {
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }
            
            const {data} = await axios.get(`${BACKEND_API_URL}/crm/company-data`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getCapitalStructureGraph({commit}, payload): Promise<boolean | object> {
        try {
            const {searchedDate, type, viewedContext} = payload;
            const formattedSearchedDate = searchedDate ? searchedDate : '';

            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/capital/graph?f[type]=${type}&f[date]=${formattedSearchedDate}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getEbitdaGraph({commit}, viewedContext): Promise<boolean | object> {
        try {
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/graph/company`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getReportingGraphs({commit}, viewedContext) {
        try {
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/graphs`, { headers });
            return Array.isArray(data) ? data : [data];
        } catch (e) {
            return false;
        }
    },
    async getCompanyWidgets({commit}, viewedContext): Promise<boolean | object> {
        try {
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/graph/company/widgets`, { headers });
            return Array.isArray(data) ? data : [data];
        } catch (e) {
            return false;
        }
    },
    async getFundWidgets({commit}, viewedContext): Promise<boolean | object> {
        try {
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/graph/fund/widgets`, { headers });
            return Array.isArray(data) ? data : [data];
        } catch (e) {
            return false;
        }
    },
    async getDealCounts({commit}, payload): Promise<boolean | object> {
        try {
            const { type, path, viewedContext } = payload;
            const headers: any = {}

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/transaction/${path}/deal-counts?f[type]=${type}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getCompanyKPIs({commit}, payload): Promise<boolean | object> {
        try {
            const {searchedDate, viewedContext} = payload;
            const formattedSearchedDate = searchedDate ? searchedDate : '';
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/kpis/company?f[date]=${formattedSearchedDate}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getFundKPIs({commit}, payload): Promise<boolean | object> {
        try {
            const {searchedDate, type, viewedContext} = payload;
            const headers: any = {}

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/kpis/fund/?f[date]=${searchedDate}&f[type]=${type}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getKPIs({commit}, payload): Promise<boolean | object> {
        try {
            const {searchedDate, type, path, viewedContext} = payload;

            const headers: any = {}

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/transaction/${path}/kpis/?f[date]=${searchedDate}&f[type]=${type}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getKeyFinancials({commit}, payload): Promise<boolean | object> {
        try {
            const {date, path, viewedContext} = payload;
            const formattedDate = date ? date : '';

            const headers: any = {}

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/reporting/key-financials/${path}?f[date]=${formattedDate}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getLastTransactions({commit}, payload): Promise<boolean | object> {
        try {
            const {searchedDate, type, path, viewedContext} = payload;
            const formattedSearchedDate = searchedDate ? searchedDate : '';
            const headers: any = { 'x-total-count': true }

            if (viewedContext) {
                headers['Viewed-Context'] = viewedContext
            }

            const {data} = await axios.get(`${BACKEND_API_URL}/transaction/${path}/last-transactions?f[date]=${formattedSearchedDate}&f[type]=${type}`, { headers });
            return data;
        } catch (e) {
            return false;
        }
    },
    async getCurrentContextData({state, commit}, id: string): Promise<Address & { name: string }> {
        const {data} = await axios.get<Address & { name: string }>(`${BACKEND_API_URL}/crm/legal-entity/${id}/address`);
        return data;
    },
    async getMenus({ state, commit}): Promise<any> {
        const {data} = await axios.get(`${BACKEND_API_URL}/menu`);
        commit('setMenus', data);
        return data;
    },
    async getRegulations({state, commit}): Promise<any> {
        const {data} = await axios.get(`${BACKEND_BASE_URL}/cms/regulations`);
        return data;
    },
    async getPrivatePolicy({state, commit}): Promise<any> {
        const {data} = await axios.get(`${BACKEND_BASE_URL}/cms/privacy-policy`);
        return data;
    },
    async getManagementTeam({state,commit}, legalEntityId: string): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/${legalEntityId}/management-team`);
        commit('setManagementTeam', data);
        return data;
    },
    async getEmployees({state,commit}): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/employee`);
        commit('setEmployees', data);
        return data;
    },
    async uploadFile({commit}, payload: UploadFileDTO): Promise<any> {
        const formData = new FormData();
        formData.append('file', payload.file);
        const { data } = await axios.post(`${BACKEND_API_URL}/file/upload`, formData, payload.config);
        return data;
    },
    async uploadPublicFile({commit}, payload: UploadFileDTO): Promise<any> {
        const formData = new FormData();
        formData.append('file', payload.file);
        const { data } = await axios.post(`${BACKEND_API_URL}/file/upload/files`, formData, payload.config);
        return data;
    },
    async getXmlFile({commit}, id: string): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/file/${id}/content`);
        return data;
    },
    async getFileById({commit}, id: string): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/file/${id}`)
        return data
    },
    async putLegalEntityLogo({commit},payload: UploadFileDTO & {legalEntityId: string}): Promise<any> {
        const formData = new FormData();
        formData.append('logo', payload.file);
        return axios.post(`${BACKEND_API_URL}/crm/legal-entity/${payload.legalEntityId}/logo`, formData, payload.config);
    },
    async getCMSWelcome({commit}){
        const { data } = await axios.get<WelcomeTextData>(`${BACKEND_API_URL}/cms/welcome`);
        commit('setCMSWelcome', data);
        return data;
    },
    async goToLandingPage(): Promise<any>{
        window.location.href = FUNDEQUATE_LP_URL;
    },
    async getFlag({commit}, id: string): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/flag/${id}`);

        return data;
    },
    async getFlags({commit}): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/flag`);
        commit('setFlags', data)
        return data;
    },
    async getNetworks({commit}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/broker-networks`);
        commit('setNetworks', data)
        return data
    },
    async postNetwork({commit}, payload: { name: string }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network`, payload)

        return data
    },
    async putNetwork({commit}, payload: { id: string, name: string }) {
        const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network/${payload.id}`, { name: payload.name })

        return data
    },
    async postFlag({commit}, payload: Flag) {
        const { data } = await axios.post(`${BACKEND_API_URL}/flag`, payload);

        return data;
    },
    async shareFlag({commit}, payload: { flagId: string, userId: string }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/flag/share`, payload);

        return data;
    },
    async removeSharedFlag({commit}, id: string) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/flag/shared-flag/${id}`);

        return data;
    },
    async putFlag({commit}, payload: { id: string, flagData: Flag }) {
        const { data } = await axios.put(`${BACKEND_API_URL}/flag/${payload.id}`, payload.flagData);

        return data;
    },
    async deleteFlag({commit}, id: string) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/flag/${id}`);

        return data;
    },
    async postFlaggedObject({commit}, payload: FlaggedObjectPayload) {
        const { data } = await axios.post(`${BACKEND_API_URL}/flagged-object`, payload);
        return data;
    },
    async deleteFlaggedObject({commit}, payload: FlaggedObjectPayload) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/flag/${payload.flagId}/object/${payload.objectId}`);

        return data;
    },
    async sendPackageRequest({commit}, payload: string) {
        const { status } = await axios.post(`${BACKEND_API_URL}/package/request`, { packageId: payload });

        return status === 200;
    },
    async getFinancialReporting({commit}, payload: { action: string, type: string | null, period: string | null }) {
        const period = (payload?.period) ? payload.type === 'dateFrom' ? `${payload.period}-01-01` : `${payload.period}-12-31` : null;
        const queryString = (payload?.type) ? `?${payload.type === 'dateFrom' ? 'dateFrom' : 'dateTo'}=${period}` : '';
        const { data } = await axios.get(`${BACKEND_API_URL}/financial-reporting/${payload.action}${queryString}`)

        return data
    }
}

export const genprox: Module<GenproxState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
