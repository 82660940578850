












import Vue from 'vue'
import Component from 'vue-class-component'
import SygniMenu from "@/components/sygni-menu/SygniMenu.vue";
import { MenuItem } from "@/modules/genprox/store/types";
import {mapState} from "vuex";
import {Watch} from "vue-property-decorator";


@Component({
  components: {SygniMenu},
  computed: {
    ...mapState('genprox', {
      menus: (state: any) => state.menu
    })
  }
})
export default class AppSideBar extends Vue {
  menus!: MenuItem[];
  activeMenu: MenuItem | null = null;

  mounted(){
    this.getMenus();
  }

  async getMenus(){
    this.setActiveMenu();
  }

  onActiveMenuChange(activeMenu: MenuItem): void {
    if(this.$route.path !== activeMenu.route) {
      if(activeMenu.route.includes('https')) {
        this.$router.push({name: 'redirect', params: {route: activeMenu.route}});
      } else {
        this.$router.push({path: activeMenu.route?.toLowerCase()})
      }
    }
  }

  @Watch('$route')
  setActiveMenu(): void {
    let found = false;
    this.menus.forEach( (menu: MenuItem) => {
      if(this.$route.path.includes(menu.route?.toLowerCase())){
        this.activeMenu = menu;
        return;
      }

      if(menu.children?.length > 0) {
        menu.children?.forEach( (menuItem: MenuItem) => {
          if(this.$route.path.includes(menuItem.route?.toLowerCase()) || this.$route.path.includes('/investor/portfolio/by-company')){
            if(this.$route.path.includes('/investor/portfolio') && menuItem.route == '/investor/portfolio/by-fund') {
              this.activeMenu = menuItem;
              found = true;
            }

            if (!found) {
              this.activeMenu = menuItem;
              found = true;
            }
            return;
          }
        });
      }
    })
  }
}
