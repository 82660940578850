import {Address} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import {MTMember} from "@/modules/genprox/models/User";

export class FundState {
    newFund: CreateFundDTO;

    constructor() {
        this.newFund = {
            registrationAddress: {},
            employee: { managementTeam: false }
        }
    }
}

export interface CreateFundDTO {
    name?: string;
    description?: string;
    taxNumber?: string;
    registerNumber?: string;
    statisticalNumber?: string;
    legalForm?: string;
    endOfFinancialPeriod?: string;
    establishmentDate?: string;
    programme?: string;
    registrationAddress?: Address;
    employee?: MTMember;
}


export interface CreateFundManagerDTO {
    name?: string;
    description?: string;
    taxNumber?: string;
    registerNumber?: string;
    statisticalNumber?: string;
    legalForm?: string;
    endOfFinancialPeriod?: string;
    establishmentDate?: string;
    registrationAddress?: Address;
    employee?: MTMember;
}
