import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const templatesModule = () => import('./containers/templatesModule.vue');
const autentiFormModule = () => import('./containers/autentiFormModule.vue');
const newParticipant = () => import('./containers/NewParticipant.vue');

export const templatesRouting: Array<RouteConfig>  = [
    {
        path: 'templates',
        name: 'templates',
        component: templatesModule,
        children: [
            {
                path: ':id',
                name: 'template',
                children: [],
            },
            {
                path: ':id/preview',
                name: 'template-preview',
                children: [],
            }
        ],
    },
    {
        path: 'capital-rise/autenti/settings',
        name: 'autentiSettings',
        component: autentiFormModule,
    },
    {
        path: 'capital-rise/autenti/new-participant',
        name: 'autentiNewParticipant',
        component: newParticipant,
    },
];
